/* eslint-disable no-bitwise */
import Image from "next/image";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function ImageFallback({ src, fallbackSrc, ...rest }) {

	const [imgSrc, setImgSrc] = useState(src);

	// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
	const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

	const triplet = (e1, e2, e3) => keyStr.charAt(e1 >> 2)
+ keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4))
+ keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6))
+ keyStr.charAt(e3 & 63);

	const rgbDataURL = (r, g, b) => `data:image/gif;base64,R0lGODlhAQABAPAA${
		triplet(0, r, g) + triplet(b, 255, 255)
	}/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

	useEffect(() => {

		setImgSrc(src);

	}, [src]);

	return (
		<Image
			{...rest}
			src={imgSrc}
			onLoadingComplete={(result) => {

				if (result.naturalWidth === 0) {

					// Broken image
					setImgSrc(fallbackSrc);

				}

			}}
			placeholder="blur"
			blurDataURL={rgbDataURL(242, 242, 242)}
			onError={() => {

				setImgSrc(fallbackSrc);

			}}
		/>
	);

}

ImageFallback.propTypes = {
	src: PropTypes.string.isRequired,
	fallbackSrc: PropTypes.string.isRequired,
};
