import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

const AlloExploreModal = (props) => {

	const prop = props;

	if (prop.isMobile) {

		return <Mobile {...props} />;

	}

	return <Desktop {...props} />;

};

export default AlloExploreModal;
