/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React from "react";
import Link from 'next/link';
import GroupCardTabs from "@fdn/group_card_tabs";
import { imageDynamic } from "Helpers/utils";
import Vars from "Consts/vars";
import NextImage from "Components/imagefallback/next_image";
import CardSearchUser from "@fdn/card_search_user";
import CardSearchTopic from "@fdn/card_search_topic";
import CardSearchArticle from "@fdn/card_search_article";
import CardSearchProduct from "@fdn/card_search_product";
import CardSearchBrand from "@fdn/card_search_brand";

const Mobile = (props) => {

	const prop = props;

	const filterProduct = Array.isArray(prop.dataBrand) ? prop.dataBrand?.filter((data) => (data.type === "product")) : [];
	const filterService = Array.isArray(prop.dataBrand) ? prop.dataBrand?.filter((data) => (data.type === "service")) : [];

	return (
		<div className="container-quick-search">
			{/* hide suggestions section: will be developed by content team  */}
			{/* {
				prop.suggestions.length
					? (prop.suggestions.filter((data, index) => index < prop.limitDataSuggest)
						.map((data) => (
							<div onClick={(e) => prop.submitSearch(e, data)} aria-hidden>
								<div key={data} className="container-quick-search__search-suggestion">
									<img src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/new-fdbr/ic-search.svg" alt="ic-search" />
									<p className="container-quick-search__suggestion-text">{data}</p>
								</div>
							</div>
						))) : null
			} */}
			{
				prop.dataProduct.length === 0 && prop.dataService.length === 0 && prop.dataBrand.length === 0
				&& prop.dataArticle.length === 0 && prop.dataUsers.length === 0
				&& prop.talk_group.length === 0 && prop.talk_topics.length === 0
					? (
						<div className="component-quick-search__section-image-error">
							<img src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/images/page-error.svg" alt="page error" className="component-tab-topic__image-error" />
						</div>
					) : (
						<>
							{
								!!prop.dataProduct && prop.dataProduct.length ? (
									<>
										<div className='title-wrapper'>
											<p className='container-quick-search__title title-product'>
												Products
											</p>
											<Link
												href={props.generateUrlViewAll({ tab: 'products' })}
												passHref
											>
												<a className='fd-body-md-regular btn-view-all'>View All</a>
											</Link>
										</div>
										<div className="container-quick-search__product">

											{
												prop.dataProduct.filter((data, index) => index < prop.limitDataProduct)
													.map((dataProduct) => (
														<CardSearchProduct
														key={dataProduct.id}
														isMobile={prop.isMobile}
														data={{
															variant: dataProduct?.name,
															name: dataProduct?.product?.name || "-",
															brand: dataProduct?.brand?.name || "-",
															image:  dataProduct?.assets[0]?.url || Vars.no_image,
															rating: dataProduct.rating?.rating_overall || 0,
															totalRating:dataProduct?.total_review || 0,
															url: dataProduct?.categories.length > 0
																? `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/products/${dataProduct?.categories[0]?.parent?.slug}/
																${dataProduct?.categories[0]?.slug}/${dataProduct?.brand?.slug}/${dataProduct?.slug}`
																: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
														}}
														/>

													))
											}
										</div>

									</>

								) : null
							}

							{!!prop.dataService && prop.dataService.length ? (
								<>
									<div className='title-wrapper'>
										<p className='container-quick-search__title title-product'>
											Salon & Clinic
										</p>
										<Link
											href={props.generateUrlViewAll({ tab: 'services' })}
											passHref
										>
											<a className='fd-body-md-regular btn-view-all'>View All</a>
										</Link>
									</div>
									<div className="container-quick-search__product">

										{
											prop.dataService.filter((data, index) => index < prop.limitDataService)
												.map((dataService) => (
													<CardSearchProduct
														key={dataService.id}
														isMobile={prop.isMobile}
														data={{
															name: dataService?.name || "-",
															brand:dataService?.service?.name || "-",
															image: dataService?.assets[0]?.url || Vars.no_image,
															rating: dataService.rating?.rating_overall || 0,
															totalRating: dataService?.total_review || 0,
															url: dataService?.categories.length > 0
																? `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/services/${dataService?.categories[0]?.parent?.slug}/
																${dataService?.categories[0]?.slug}/${dataService?.brand?.slug}/${dataService?.slug}`
																: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
														}}
													/>

												))
										}
									</div>
								</>
							) : null}

							{
								!!prop.dataBrand && prop.dataBrand.length ? (
									<div className="container-quick-search__brand-section">
										<p className="container-quick-search__title title-brand">Brands</p>
										{
											filterProduct.length > 0 ? (
												<>
													<div className='sub-title-wrapper'>
														<p className='container-quick-search__subtitle'>
															Product
														</p>
														<Link
															href={props.generateUrlViewAll({
																tab: 'brands',
																type: 'product',
															})}
															passHref
														>
															<a className='fd-body-md-regular btn-view-all'>
																View All
															</a>
														</Link>
													</div>
													<div className="container-quick-search__brand-card-container">
														{
															filterProduct.map((data) => (
																<CardSearchBrand
																	key={data?.id}
																	isMobile={prop.isMobile}
																	brokenImage={Vars.imageBrokenDefault.square}
																	brand={{
																		name: data?.name || "-",
																		slug: data?.slug || "-",
																		type: data?.type || "-",
																		logo: data?.logo || Vars.no_image,
																		url: `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/brands/${data?.type}/${data?.slug}`,
																	}}
																/>

															))
														}

													</div>
												</>
											) : null
										}
										{
											filterService.length > 0 ? (
												<>
													{/* brand salon section */}
													<div className='sub-title-wrapper sub-title-wrapper-margin'>
														<p className='container-quick-search__subtitle'>
															Salon & Clinic
														</p>
														<Link
															href={props.generateUrlViewAll({
																tab: 'brands',
																type: 'service',
															})}
															passHref
														>
															<a className='fd-body-md-regular btn-view-all'>
																View All
															</a>
														</Link>
													</div>
													<div className="container-quick-search__brand-card-container">
														{
															filterService.map((data) => (
																<CardSearchBrand
																	key={data?.id}
																	isMobile={prop.isMobile}
																	brokenImage={Vars.imageBrokenDefault.square}
																	brand={{
																		name: data?.name || "-",
																		slug: data?.slug || "-",
																		type: data?.type || "-",
																		logo: data?.logo || Vars.no_image,
																		url: `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/brands/${data?.type}/${data?.slug}`,
																	}}
																/>

															))
														}
													</div>
												</>
											) : null
										}
									
									</div>
								) : null
								
							}
							{
								!!prop.dataArticle && prop.dataArticle.length ? (
									<>
										<div className='title-wrapper'>
											<p className="container-quick-search__title title-article">{prop.dataArticle?.length > 0 && "Articles"}</p>
											<Link
												href={props.generateUrlViewAll({ tab: 'articles' })}
												passHref
											>
												<a className='fd-body-md-regular btn-view-all'>View All</a>
											</Link>
										</div>
										<div className="container-quick-search__article">
										{
											prop.dataArticle.filter((data, index) => index < prop.limitDataArticle)
												.map((data) => (

													<CardSearchArticle
														key={data.id}
														isMobile={prop.isMobile}
														article={data}
													>
														<NextImage
															width={100}
															height={66}
															placeholder="article"
															objectFit="cover"
															style={{ borderRadius: 4 }}
															src={data.image}
															alt={data.title}
															fallbackSrc={Vars.imageBrokenDefault.rectangle}
														/>
													</CardSearchArticle>

												))
										}
										</div>
									</>
								) : null
							}

							{
								!!prop.dataUsers && prop.dataUsers.length ? (
									<>
										<div className='title-wrapper'>
											<p className="container-quick-search__title title-user">{prop.dataUsers?.length > 0 && "Users"}</p>
											<Link
												href={props.generateUrlViewAll({ tab: 'users' })}
												passHref
											>
												<a className='fd-body-md-regular btn-view-all'>View All</a>
											</Link>
										</div>
										<div className="container-quick-search__users">
											{
												prop.dataUsers.filter((data, index) => index < prop.limitDataUser)
													.map((data) => (
														<CardSearchUser
															key={data.usrapo_id}
															isMobile={prop.isMobile}
															domain={process.env.NEXT_PUBLIC_SSO_DOMAIN}
															user={data}
															brokenImage={Vars.imageBrokenDefault.square}
														>
															<NextImage
																width={60}
																height={60}
																placeholder="user"
																objectFit="cover"
																style={{ borderRadius: "50%" }}
																src={data.usrpic || Vars.imageBrokenDefault.user}
																alt={data.username}
																fallbackSrc={Vars.imageBrokenDefault.user}
															/>
														</CardSearchUser>
													))
											}
										</div>

									</>
								) : null
							}

							{
								!!prop.talk_group && prop.talk_group.length ? (
									<>
										<div className='title-wrapper'>
											<p className="container-quick-search__title title-talk-groups">{prop.talk_group?.length > 0 && "FD Talk Groups"}</p>
											<Link
												href={props.generateUrlViewAll({ tab: 'groups' })}
												passHref
											>
												<a className='fd-body-md-regular btn-view-all'>View All</a>
											</Link>
										</div>
										<div className="container-quick-search__talk-groups">
											{/* {!!prop.talk_group && prop.talk_group.length */}
											{/* ?  */}
											{prop.talk_group.filter((data, index) => index < prop.limitDataTalkGroup)
												.map((item) => (
													<div
														className="newest-groups-card-item"
														key={item.id}
													>
														<GroupCardTabs
															key={item.id}
															group={item}
															element_id="id_group_name_homepage"
															domain={process.env.NEXT_PUBLIC_TALK_DOMAIN}
															endpoint={imageDynamic(
																`https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-talk${process.env.NEXT_PUBLIC_NODE_ENV === "development" ? "-dev" : ""}`,
																100,
															)}
															defaultPicture={prop.defaultPicture}
														/>
													</div>
												))}

										</div>

									</>
								) : null
							}

							{
								!!prop.talk_topics && prop.talk_topics.length ? (
									<>
										<div className='title-wrapper'>
											<p className="container-quick-search__title title-talk-topics">{prop.talk_topics?.length > 0 && "Topics"}</p>
											<Link
												href={props.generateUrlViewAll({ tab: 'topics' })}
												passHref
											>
												<a className='fd-body-md-regular btn-view-all'>View All</a>
											</Link>
										</div>
										<div
											className="container-search-result__section-talk-topics"
										>
											{prop.talk_topics.filter((data, index) => index < prop.limitDataTalkTopic)
												.map((item) => (
													<div key={item.id} className="container-search-result__talk-topics">
														<CardSearchTopic
															isMobile={prop.isMobile}
															topic={item}
															domain={process.env.NEXT_PUBLIC_TALK_DOMAIN}
														/>
													</div>
												))}
										</div>

									</>
								) : null
							}
						</>
					)
			}

			<style>
				{`
					.container-quick-search .container-quick-search__talk-groups .txt-group-info{
						font-family: 'Creato Display';
						font-style: normal;
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						color: #13161A;
					}
					.container-quick-search .container-quick-search__talk-groups .newest-groups-card {
						display: flex;
						margin: 0;
						width: 32%;
					}
					.readmore-txt {
						pointer-events: none;
					}
				`}
			</style>
			<style jsx>
				{`
					.container-search-result__section-talk-topics {
						padding-right: 11px;
					}
					.container-quick-search{
						padding: 10px 5px 16px 16px;
						margin-bottom: 40px;
					}
					.container-quick-search__search-suggestion{
						display: flex;
						column-gap: 14px;
						margin-bottom:24px;
					}
					.container-quick-search__suggestion-text{
						font-family: 'Creato Display';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						color: #13161A;
					}
					.container-quick-search__title{
						font-family: 'Creato Display';
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: #13161A;
					}
					.container-quick-search__subtitle{
						font-family: 'Creato Display';
						font-style: normal;
						font-weight: 500;
						font-size: .875rem;
						line-height: 1.25rem;
						letter-spacing: .026rem;
						color: #13161A;
					}
					.container-quick-search__brand-section {
						margin-bottom: 24px;
					}
					.container-quick-search__brand-card-container{
						display: flex;
						gap: 12px;
						margin-bottom: 12px;
						overflow-x: scroll !important;
						overflow-y: hidden;
						white-space: nowrap;
						-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
						padding-right: var(--space-12);
					}
					.container-quick-search__brand-card-container::-webkit-scrollbar {
						display: none;
					}

					.container-quick-search__section-image-brand{
						height: 50px;
						width: 100px;
						padding: 5px 10px;

					}
					.container-quick-search__image-brand{
						width: -webkit-fill-available;
					    height: -webkit-fill-available;
						object-fit: contain;
					}
					.container-quick-search__product{
						display: flex;
						column-gap: 12px;
						overflow-x: scroll !important;
    					overflow-y: hidden;
						white-space: nowrap;
                    	-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
						margin-bottom:24px;
						padding-right: var(--space-12);
                    }
					.container-quick-search__product::-webkit-scrollbar {
						display: none;
					}
					.container-quick-search__article{
						margin-bottom:24px;
					}
					.container-quick-search__trending-search{
						margin-top:24px;
					}
					.container-quick-search__users{
						display: flex;
						overflow-x: scroll !important;
    					overflow-y: hidden;
						white-space: nowrap;
                    	-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
						margin-bottom:24px;
						padding-right: var(--space-12);
                    }
					.container-quick-search__users::-webkit-scrollbar {
						display: none;
					}
					.container-quick-search__talk-groups{
						display: flex;
						gap: 25px;
						overflow-x: scroll !important;
    					overflow-y: hidden;
						white-space: nowrap;
                    	-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
						margin-bottom:24px;
						padding-right: var(--space-12);
                    }
					.container-quick-search__talk-groups::-webkit-scrollbar {
						display: none;
					}
					.loader-wrapper{
						display: flex;
    					justify-content: center;
					}
					.component-quick-search__section-image-error{
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.title-wrapper {
						width: 100%;
						height: fit-content;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: var(--space-12);
						padding-right: 11px;
						box-sizing: border-box;
					}
					.sub-title-wrapper {
						width: 100%;
						height: fit-content;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 8px;
						padding-right: 11px;
						box-sizing: border-box;
					}
					.sub-title-wrapper-margin {
						margin-top: var(--space-12);
					}
					.btn-view-all {
						color: var(--text-link);
						transition: color 0.2s ease-in-out;
					}
					.title-brand {
						margin-bottom: var(--space-12);
					}
      			`}
			</style>
		</div>

	);

};

export default Mobile;
