/* eslint-disable react/jsx-props-no-multi-spaces */
import Desktop from "./desktop";
import Mobile from "./mobile";

const SearchDefault = (props) => {

	const prop = props;

	if (prop.isMobile) {

		return (
			<Mobile
				{...prop}
			/>
		);

	}
	return (
		<Desktop {...prop} />
	);

};

export default SearchDefault;
