/* eslint-disable react/jsx-props-no-multi-spaces */
import Desktop from "./desktop";
import Mobile from "./mobile";

const QuickSearch = (props) => {

	const {
		searchText,
		...restProps
	} = props;

	const generateUrlViewAll = (payload) => {
		const query = new URLSearchParams({
			q: searchText,
			tab: payload.tab,
			...(payload.type && { type: payload.type }),
		});
		const url = `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/search?${query}`;
		return url;
	};

	if (restProps.isMobile) {

		return (
			<Mobile
				{...restProps}
				generateUrlViewAll={generateUrlViewAll}
			/>
		);

	}
	return (
		<Desktop {...restProps} generateUrlViewAll={generateUrlViewAll}/>
	);

};

export default QuickSearch;
