import React from "react";
import Vars from "Consts/vars";
import Image from "next/image";
import dynamic from 'next/dynamic'
import NextImage from "Components/imagefallback/next_image";
import CardSearchBrand from "@fdn/card_search_brand";
import CardSearchProduct from "@fdn/card_search_product";
import CardSearchArticle from "@fdn/card_search_article";
import Promotion from "@fdn/card_search_promotion";
const SearchHistory = dynamic(() => import('@fdn/history_search'), {
  ssr: false,
})
import CardSearchTrending from "@fdn/card_search_trending";

const Desktop = (props) => {

	const prop = props;

	return (
		<div className="container-search-default">
			{prop.trending && (
				<div>
					{prop.searchHistory && prop.searchHistory?.length > 0 ? (
						<div className="container-search-default__search-history">
							<SearchHistory
								id="search-history"
								isMobile={false}
								histories={prop.searchHistory}
								onClickRemove={prop.deleteHistorySearch}
								onClickClear={prop.clearHistorySearch}
								limit={5}
								on_click={prop.onSetDataLayer}
								env={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
							/>
						</div>
					) : null}
					{prop.dataPromotion && prop.dataPromotion?.length > 0 ? (
						<>
						<p className="container-search-default__title">
							{prop.titlePromotion}
						</p>
						<div className="list-promotion">
							{
								prop.dataPromotion?.map((data, idx) => (
									idx < prop.limitDataPromotion ? (
										<Promotion
											key={idx}
											isMobile={prop.isMobile}
											promotion={data}
											on_click={() => prop.onSetDataLayer(data.title, "promotion")}
										>
											<NextImage
												width={40}
												height={40}
												placeholder="user"
												objectFit="cover"
												style={{ borderRadius: "4px" }}
												src={data.image || Vars.imageBrokenDefault.rectangle}
												alt={data.label}
												fallbackSrc={Vars.imageBrokenDefault.rectangle}
											/>
										</Promotion>
									) : null
								))
							}
						</div>
					</>
					) : null}
					{/* Section Popular */}
					<p className="container-search-default__title">
						{prop.titlePopular}
					</p>
					<div className="container-search-default__section-brand">
						{prop.dataBrand?.length
							? prop.dataBrand
								?.slice(0, prop.limitDataBrand)
								.map((data) => (
									<CardSearchBrand
										key={data.id}
										isMobile={prop.isMobile}
										brokenImage={Vars.imageBrokenDefault.square}
										brand={{
											name: data?.name || "-",
											slug: data?.slug || "-",
											type: data?.type || "-",
											logo: data?.logo || Vars.no_image,
											url: `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/brands/${data?.type}/${data?.slug}`,
										}}
										onClick={() => prop.onSetDataLayer(data.name, "brand")}
									/>
								)) : null}
					</div>

					<div className="container-search-default__product">
						{prop.dataProduct?.length
							? prop.dataProduct
								?.slice(0, prop.limitDataProduct)
								.map((data) => (
									<CardSearchProduct
										key={data.id}
										isMobile={prop.isMobile}
										data={{
											variant: data?.name,
											name:  data?.product?.name || "-",
											brand: data?.brand?.name || "-",
											image: data?.assets.length > 0 ? data?.assets[0].url : Vars.no_image,
											rating: data.rating?.rating_overall || 0,
											totalRating: data?.total_review || 0,
											url: data?.categories.length > 0
												? `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/products/${data?.categories[0]?.parent?.slug}/
												${data?.categories[0]?.slug}/${data?.brand?.slug}/${data?.slug}`
												: process.env.NEXT_PUBLIC_REVIEWS_DOMAIN,
										}}
										on_click={() => prop.onSetDataLayer(`${data.brand?.name} ${data.name} ${data.shade || ""}`, "product")}
									/>

								)) : null}
					</div>

					<div className="container-search-default__article">
						{prop.dataArticle?.length
							? prop.dataArticle
								?.slice(0, prop.limitDataArticle)
								.map((data) => (
									<CardSearchArticle
										key={data.id}
										isMobile={prop.isMobile}
										article={data}
										on_click={() => prop.onSetDataLayer(data.title, "article")}
									>
										<Image
											width={100}
											height={66}
											placeholder="article"
											objectFit="cover"
											style={{ borderRadius: 4 }}
											src={data.image}
											alt={data.title}
										/>
									</CardSearchArticle>
								)) : null}
					</div>

					<p className="container-search-default__title-trending">
						{ prop.trending?.name ? prop.trending.name : "Trending Search"}
					</p>
					<div className="container-search-default__trending-search">
						{ prop.trending?.section?.length ? (
							prop.trending?.section?.map((data) => (
								<CardSearchTrending
									key={data}
									isMobile={prop.isMobile}
									data={data}
									submitSearch={prop.submitSearch}
									on_click={() => prop.onSetDataLayer(data, "trending keyword")}
								/>
							))) : null}

					</div>
				</div>
			)}

			<style>
				{`
					.container-search-default .container-search-default__section-brand .container-search-default__image-brand{
						object-fit: contain;
						width: 100%;
						height: 100%;
					}
				`}
			</style>

			<style jsx>
				{`
				.list-promotion {
					gap: 12px;
    			display: grid;
				}
					.loader-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.container-search-default {
						padding: 10px 5px 24px 24px;
					}
					.container-search-default__title {
						font-family: "Creato Display";
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: #13161a;
						margin: 20px 0 12px 0;
					}
					.container-search-default__section-brand {
						display: flex;
						gap: 12px;
						margin-bottom: 12px;
						overflow-x: scroll;
						overflow-y: hidden;
						white-space: nowrap;
						-ms-overflow-style: none; /* IE and Edge */
						scrollbar-width: none; /* Firefox */
					}
					.container-search-default__section-brand::-webkit-scrollbar {
						display: none;
					}

					.container-search-default__section-image-brand {
						height: 50px;
						width: 100px;
						padding: 5px 10px;
					}
					.container-search-default__image-brand {
						width: -webkit-fill-available;
						height: -webkit-fill-available;
						object-fit: contain;
					}
					.container-search-default__product {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 12px;
						justify-content: space-between !important;
					}
					.container-search-default__article {
						margin-top: 20px;
					}
					.container-search-default__trending-search {
						display: flex;
						flex-wrap: wrap;
						gap: 12px;
					}
					.container-search-default__title-trending{
						font-family: "Creato Display";
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 24px;
						margin-bottom: 12px;
						margin-top: 20px;
					}
				`}
			</style>
		</div>
	);

};

export default Desktop;
